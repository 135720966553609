import { MenuProps } from '~/constants/menu/props';
import IconHome from '~/icons/Home';

const menu: MenuProps = (espaceId?: string) => [
  {
    acl: {
      '*': true,
    },
    className: 'hidden md:flex',
    iconLeft: IconHome,
    name: 'Tableau de bord',
    profil: 'dashboard',
    to: `/espaces/${espaceId}/dashboard`,
  },
];

export default menu;
