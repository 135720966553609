import React, { FC, ReactNode } from 'react';

import Layout from '~/components/Layout';
import menu from '~/constants/menu/espace';
import { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
// import useDetectKeyboard from '~/utils/useDetectKeyboard';

interface TemplateEspaceProps {
  collectionName?: string;
  docId?: string;
  pathname?: string;
  secondary?: ReactNode;
  title?: string;
}

const TemplateEspace: FC<TemplateEspaceProps & EspaceProps & UserProps> = ({
  children,
  collectionName,
  docId,
  espace,
  pathname,
  secondary,
  title,
  user,
}) => (
  // const isOpen = useDetectKeyboard();

  // useEffect(() => {
  //   if (isOpen) {
  //     console.info("le clavier est visible sur l'écran");
  //   } else {
  //     console.info("le clavier n'est pas visible sur l'écran");
  //   }
  // }, [isOpen]);

  <Layout
    collectionName={collectionName}
    docId={docId}
    espaceId={espace.id}
    kind="espace"
    menu={menu}
    pathname={pathname}
    showNavigation
    user={user}
  >
    <div>
      {title && <h1 className="mb-6 text-xl font-bold">{title}</h1>}
      {undefined !== secondary && (
        <>
          {secondary}
          <hr className="my-6" />
        </>
      )}

      <div>{children}</div>
    </div>
  </Layout>
);
export default TemplateEspace;
